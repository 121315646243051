import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Input, Modal } from 'semantic-ui-react';
import logo from '../../../src/logo.png';
import { useAuth0 } from '../../react-auth0-wrapper';
import ModalCreateOrg from './ModalCreateOrg';
import OrgSelect from './OrgSelect';
import Toast from './Toast';
import {ETHSTATS_IDCHAIN_URL, ETHSTATS_EVERCHAIN_URL, BLOCK_EXPLORER_IDCHAIN_URL, BLOCK_EXPLORER_EVERCHAIN_URL} from "../../js/variables";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  imgLogoMenu: {
    width: '64px',
  },
  logout: {
    paddingTop: '10px',
    textAlign: 'center'
  }
}));

const NavBar = (props) => {

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [showModalCreateOrg, setShowModalCreateOrg] = useState(false);

  const handleDrawerOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [breadcrumb, setBreadcrumb] = useState('');
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const [filtered, setFiltered] = useState(props.metaData);
  const [query, setQuery] = useState('');

  const breadcrumbs = {
    agents: 'sidebar_agents',
    members: 'sidebar_members',
    transactions: 'sidebar_transactions',
    reports: 'sidebar_reports'
    // kinabank: 'sidebar_kina_bank'
  };

  const handleBreadcrumb = (path) => {
    setBreadcrumb(breadcrumbs[path]);
  };

  useEffect(() => {
    handleBreadcrumb(window.location.pathname.split('/').pop());
  }, [window.location.pathname]);

  useEffect(() => {
    if (!query || query.trim().length === 0) {
      setFiltered(props.metaData)
    } else {
      let lowercasedFilter = query.toLowerCase();
      let filteredData = { ...props.metaData, organizations_tree: {} };
      Object.keys(props.metaData.organizations_tree).forEach((key) => {
        let arr = props.metaData.organizations_tree[key];
        let filteredOrgs = [];
        if (arr) {
          arr.forEach(org => {
            if (org.toLowerCase().includes(lowercasedFilter)) {
              filteredOrgs.push(org);
            }
          })
        }
        if (filteredOrgs.length > 0 || key.toLowerCase().includes(lowercasedFilter)) {
          if (arr) {
            filteredData.organizations_tree[key] = filteredOrgs;
          } else {
            filteredData.organizations_tree[key] = arr;
          }
        }
      });
      setFiltered(filteredData);
    }
  }, [query]);

  if (!props.metaData) {
    return <></>;
  }

  const { role_name } = props.metaData;

  const redirectView = (data) => {
    localStorage.setItem('nameSection', data);
    switch (data) {
      case 'sidebar_agents':
        localStorage.setItem('linkSection', '/agents');
        break;
      case 'sidebar_members':
        localStorage.setItem('linkSection', '/members');
        break;
      case 'sidebar_transactions':
        localStorage.setItem('linkSection', '/transactions');
        break;
      case 'sidebar_reports':
        localStorage.setItem('linkSection', '/reports');
        break;
      case 'referral_codes':
          localStorage.setItem('linkSection', '/referralCodes');
        break;
      case 'org-profile':
          localStorage.setItem('linkSection', '/org-profile-list');
          break;
      // case 'sidebar_kina_bank':
      //   localStorage.setItem('linkSection', '/kinabank');
      //   break;
      default:
        return;
    }
    props.setNameTitleModule(data);
    setOpen(false);
  }

  const onCloseModal = () => {
    setShowModalCreateOrg(false);
  };

  var bgColors = {
    "Blue": "#293f65",
    "White": "white"
  };
  return (
    <>
      <div style={{ marginBottom: 40 }}>
        <CssBaseline />
        <AppBar
          style={{ backgroundColor: bgColors.Blue }}
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <div className="sizeContainer">
            <Toolbar className="imgLogoHeader">
              <div className={classes.title}>

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  style={{ borderRadius: 0 }}
                >
                  <Icon id='menu-icon' style={{ marginRight: 20, color: bgColors.White }}
                    name={open ? 'x' : 'bars'}
                  />
                </IconButton>
                <InputLabel shrink htmlFor="dropdown-org-select" className="labelUpShadow" style={{ color: 'black' }}>
                  Organizations</InputLabel>
                <Dropdown
                  text={props.currentOrg}
                  className="selection">
                  <Dropdown.Menu>
                    <Input value={query} onChange={event => setQuery(event.target.value)} onClick={e => e.stopPropagation()} icon='search' iconPosition='left' className='search' />
                    <OrgSelect
                      role_name={role_name}
                      onClick={evt => { evt.stopPropagation() }}
                      metaData={filtered}
                      currentOrg={props.currentOrg}
                      setCurrentOrg={props.setCurrentOrg}
                      nameTitleModule={props.nameTitleModule}
                      setNameTitleModule={props.setNameTitleModule}
                      showModalCreateOrg={showModalCreateOrg}
                      setShowModalCreateOrg={setShowModalCreateOrg} />
                  </Dropdown.Menu>
                </Dropdown>
                <Typography variant="h6" noWrap style={{ display: "inline-flex", padding: 7 }}>
                  {t(props.nameTitleModule)}
                </Typography>
              </div>
              <img src={logo} style={{ float: "right" }} alt="" />
            </Toolbar>
          </div>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >

          <div className={classes.drawerHeader}>
            <img src={logo} className={classes.imgLogoMenu} style={{ marginRight: 100 }} alt="" />
            <IconButton style={{ borderRadius: 0, marginRight: -8 }} onClick={handleDrawerClose}>
              <div className={classes.drawerHeader}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </div>
            </IconButton>
          </div>

          {(!!window.ADMIN_SUB_ORGNAME === true || role_name === 'SUPER_ADMIN') && (
            <>
              <Divider />
              <List>
                <ListItem button component={Link} to="/org-profile-list" onClick={() => redirectView('org-profile')}>
                    <ListItemText primary={<b>{t('org-profile')}</b>} />
                </ListItem>

                <ListItem button component={Link} to="/agents" onClick={() => redirectView('sidebar_agents')}>
                  <ListItemText primary={<b>{t('sidebar_agents')}</b>} />
                </ListItem>

                <ListItem button component={Link} to="/members" onClick={() => redirectView('sidebar_members')}>
                  <ListItemText primary={<b>{t('sidebar_members')}</b>} />
                </ListItem>

                <ListItem button component={Link} to="/transactions" onClick={() => redirectView('sidebar_transactions')}>
                  <ListItemText primary={<b>{t('sidebar_transactions')}</b>} />
                </ListItem>

                {/*<ListItem button component={Link} to="/reports" onClick={() => redirectView('sidebar_reports')}>*/}
                  {/*<ListItemText primary={<b>{t('sidebar_reports')}</b>} />*/}
                {/*</ListItem>*/}

                  <ListItem button component={Link} to="/referralCodes" onClick={() => redirectView('referral_codes')}>
                      <ListItemText primary={<b>{t('referral_codes')}</b>} />
                  </ListItem>
              </List>
            </>
          )}
          {/* {(!!window.ADMIN_SUB_ORGNAME === true || role_name === 'SUPER_ADMIN') && (
            <>
              <Divider />
              <List>
                <ListItem button component={Link} to="/kinabank" onClick={() => redirectView('sidebar_kina_bank')}>
                  <ListItemText primary={<b>{t('sidebar_kina_bank')}</b>} />
                </ListItem>
              </List>
            </>
          )} */}
          {role_name === 'SUPER_ADMIN' && (
            <>
              <Divider />
              <List>

                <ListItem button target="_blank" rel="noopener noreferrer" component="a" href={ETHSTATS_IDCHAIN_URL}>
                  <ListItemText primary={<b>EthStats (IDchain)</b>} />
                </ListItem>

                <ListItem button href={ETHSTATS_EVERCHAIN_URL} component="a" target="_blank" rel="noopener noreferrer">
                  <ListItemText primary={<b>EthStats (EverChain)</b>} />
                </ListItem>

                <ListItem button href={BLOCK_EXPLORER_IDCHAIN_URL} component="a" target="_blank" rel="noopener noreferrer">
                  <ListItemText primary={<b>Block Explorer (IDchain)</b>} />
                </ListItem>

                <ListItem button href={BLOCK_EXPLORER_EVERCHAIN_URL} component="a" target="_blank" rel="noopener noreferrer">
                  <ListItemText primary={<b>Block Explorer (EverChain)</b>} />
                </ListItem>

              </List>
              <Divider />
            </>
          )}
          <List>
            <ListItem button style={{ backgroundColor: '#fa4261', color: 'white' }} component={Link} to="/logout" onClick={() => logout({ returnTo: window.location.origin })}>
              <ListItemText primary={<b>{t`logout`}</b>} />
            </ListItem>
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
        </main>
      </div>

      <Modal style={{ marginTop: '25px' }}
        open={showModalCreateOrg}
        onClose={onCloseModal}>
        <ModalCreateOrg
          showModalCreateOrg={showModalCreateOrg}
          setShowModalCreateOrg={setShowModalCreateOrg}
          currentOrg={props.currentOrg}
          setCurrentOrg={props.setCurrentOrg}
          metaData={filtered}
          setDataToast={props.setDataToast}
          reloadINF={props.reloadINF} />
      </Modal>
      <Toast dataToast={props.dataToast}
        setDataToast={props.setDataToast} />
    </>
  );
};

export default NavBar;